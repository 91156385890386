import Vue from 'vue'
import VueRouter from 'vue-router'
import authService from '../auth/authService.js'
import store from '../store/index.js'
import i18n from '../i18n/i18n'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {x: 0, y: 0}
    },
    routes: [
        {
            path: '/',
            name: 'home',
            redirect: 'dash',
            meta: {
                pageTitle: i18n.t('general.home'),
                requiresAuth: true
                // roles: ['admin'] // dovolimo samo adminu vpogled na to stran
                // roles: ['user', 'superuser'] // dovoimo samo userju in superuserju
                // če pustimo prazno, se roles ne upošteva
            }
        },
        {
            path: '/uporabniki',
            name: 'users',
            component: () => import('@/views/Users.vue'),
            meta: {
                pageTitle: 'Uporabniki',
                requiresAuth: true
            }
        },
        {
            path: '/narocila',
            name: 'narocila',
            component: () => import('@/views/Narocila'),
            meta: {
                pageTitle: 'Naročila',
                requiresAuth: true
            }
        },
        {
            path: '/cakalna_vrsta',
            name: 'cakalna_vrsta',
            component: () => import('@/views/NarocilaCakalanaVrsta'),
            meta: {
                pageTitle: 'Čakalna vrsta',
                requiresAuth: true
            }
        },
        {
            path: '/vloge',
            name: 'vloge',
            component: () => import('@/views/Vloge.vue'),
            meta: {
                pageTitle: 'Vloge',
                requiresAuth: true
            }
        },
        {
            path: '/zaloga/kosi',
            name: 'zaloga',
            component: () => import('@/views/Zaloga.vue'),
            meta: {
                pageTitle: 'Zaloga - Kosi',
                requiresAuth: true
            }
        },
        {
            path: '/zaloga/material',
            name: 'zalogaMaterial',
            component: () => import('@/views/ZalogaMaterial.vue'),
            meta: {
                pageTitle: 'Zaloga - Material',
                requiresAuth: true
            }
        },
        {
            path: '/plani/vsi',
            name: 'plansAll',
            component: () => import('@/views/Plans'),
            meta: {
                pageTitle: 'Plani',
                requiresAuth: true
            },
            props: {planType: 'all'}
        },
        {
            path: '/plani/potrjeni',
            name: 'plansConfirmed',
            component: () => import('@/views/Plans'),
            meta: {
                pageTitle: 'Plani',
                requiresAuth: true
            },
            props: {planType: 'potrjen'}
        },
        {
            path: '/plani/odprti',
            name: 'plansOpened',
            component: () => import('@/views/Plans'),
            meta: {
                pageTitle: 'Plani',
                requiresAuth: true
            },
            props: {planType: 'odprt'}
        },
        {
            path: '/plani/zakljuceni',
            name: 'plansFinished',
            component: () => import('@/views/Plans'),
            meta: {
                pageTitle: 'Plani',
                requiresAuth: true
            },
            props: {planType: 'zaključen'}
        },
        {
            path: '/plan/:planId',
            name: 'plan',
            component: () => import('@/views/MaterialPlan.vue'),
            meta: {
                pageTitle: 'Plan',
                requiresAuth: true
            }
        },
        {
            path: '/profil',
            name: 'profil',
            component: () => import('@/views/Profil'),
            meta: {
                pageTitle: 'Profil',
                requiresAuth: true
            }
        },
        {
            path : '/config',
            name: 'config',
            component: () => import('@/views/CommonConfig.vue'),
            meta: {
                pageTitle: 'Konfiguracija',
                requiresAuth: true
            }
        },
        {
            path : '/statistika',
            name: 'statistika',
            component: () => import('@/views/Statistika.vue'),
            meta: {
                pageTitle: 'Statistika',
                requiresAuth: true
            }
        },
        {
            path : '/dash',
            name: 'dash',
            component: () => import('@/views/Dashboard.vue'),
            meta: {
                pageTitle: 'Pregled',
                requiresAuth: true
            }
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('@/views/Login.vue'),
            meta: {
                layout: 'full'
            }
        },
        {
            path: '/error-404',
            name: 'error-404',
            component: () => import('@/views/error/Error404.vue'),
            meta: {
                layout: 'full'
            }
        },
        {
            path: '*',
            redirect: 'error-404'
        }
    ]
})

router.beforeEach((to, from, next) => {

    // pridobi loggedIn spremenljivko iz localStorage, ki jo ob loginu nastavimo na true
    const loggedIn = sessionStorage.getItem('loggedIn')
    if (loggedIn && !store.state.user.loggedIn) {
        // ob reloadu strani (Ctrl + R) se vrednosti iz store (Vuex Store) pobrišejo
        // zato jih pridobimo iz localStorage in jih shranimo
        store.state.user.loggedIn = true
        const userData = sessionStorage.getItem('userData')
        if (userData) {
            store.state.user.userData = JSON.parse(userData)
        }
    }

    // če stran, na katero želimo iti vsebuje meta podatek requiresAuth
    // in iz authService zvemo, da user ni prijavljen, ga pošljemo na login
    if (to.meta.requiresAuth && !authService.isAuthenticated()) {
        return next('/login')
    }

    // če meta vsebuje array roles (npr ['user', 'superuser'] ali ['admin'])
    // pogledamo v vuex store, če ima prijavljen uporabnik ta role
    if (to.meta.permissions) {
        if (store.state.user.userData.role.permissions && to.meta.permissions.some(p => store.state.user.userData.role.permissions.includes(p))) {
            return next() // z next() spustimo dalje
        }
        return next('/login')
    }
    next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})

export default router
