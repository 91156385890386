export default {
    PermissionDoEverything: -1,
    PermissionGetOwnDetails: 100,
    PermissionUpdateOwnDetails: 101,
    PermissionGetUser: 300,
    PermissionCreateUser: 301,
    PermissionUpdateUser: 302,
    PermissionDeleteUser: 303,
    PermissionGetRole: 400,
    PermissionCreateRole: 401,
    PermissionUpdateRole: 402,
    PermissionDeleteRole: 403,
    PermissionGetCuttingPlan: 500,
    PermissionCreateCuttingPlan: 501,
    PermissionUpdateCuttingPlan: 502,
    PermissionDeleteCuttingPlan: 503,
    PermissionReserveCuttingPlan: 504,
    PermissionConfirmCuttingPlan: 505,
    PermissionGetOrder: 600,
    PermissionGetStock: 601,
    PermissionGetConfig: 700,
    PermissionSetConfig: 701
}
