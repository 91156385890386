import authService from '../../auth/authService'

const userDefaults = {
  uid: 0,
  displayName: '',
  about: '',
  photoURL: require('@/assets/images/portrait/small/avatar-s-11.jpg'), // From Auth
  status: 'online',
  userRole: '/'
}

export default {
  namespaced: true,
  state: {
    AppActiveUser: userDefaults,
    loggedIn: false,
    userData: null,
    planning: false
  },
  getters: {},
  mutations: {
    SET_LOGGED_IN(state) {
      state.loggedIn = true
    },
    UPDATE_USER_DATA(state, data) {
      state.userData = data
    },
    SET_PLANNING(state, payload) {
        state.planning = payload
    }
  },
  actions: {
    setPlanning({ commit }, payload) {
        commit('SET_PLANNING', payload)
    },
    login({ commit }, payload) {
      authService.setLoginParameters(payload)
      commit('SET_LOGGED_IN')
    },
    logOut() {
      authService.logOut()
    },
    setUserData({ commit }, payload) {
      commit('UPDATE_USER_DATA', payload)
      sessionStorage.setItem('userData', JSON.stringify(payload))

      window.location = '/'
    }
  }
}
