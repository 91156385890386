export default {
  sl: {
    general: {
      profile: 'Profil',
      home: 'Domov',
      users: 'Uporabniki',
      narocila: 'Naročila',
      vloge: 'Vloge',
      profil: 'Profil',
      plani: 'Plani',
      vsi: 'Vsi',
      potrjeni: 'Potrjeni',
      odprti: 'Odprti',
      zakljuceni: 'Zaključeni',
      plan: 'Plan',
      statistics: 'Statistika',
      no: 'Ne',
      yes: 'Da',
      cakalna_vrsta: 'Čakalna vrsta',
      cancel: 'Prekini',
      zaloga: 'Zaloga',
      kosi: 'Kosi',
      material: 'Material',
      dashboard: 'Dashboard'
    },

    login: {
      greeting: 'Dobrodošli!',
      login: 'Prijavi se',
      rememberMe: 'Zapomni si me',
      password: 'Geslo',
      forgottenPassword: 'Pozabljeno geslo?',
      logout: 'Odjavi se',
      wrong_password: 'Geslo je napačno!',
      newAccount: 'Registriraj se'
    },
    user: {
      addUser: 'Dodaj uporabnika',
      login: 'Prijavi se',
      rememberMe: 'Zapomni si me',
      password: 'Geslo',
      forgottenPassword: 'Pozabljeno geslo?',
      logout: 'Odjavi se',
      wrong_password: 'Geslo je napačno!',
      newAccount: 'Registriraj se'
    }
  },
  en: {
      general: {
        profile: 'Profile',
        home: 'Home',
        second_page: 'Second page',
        statistics: 'Statistics',
        no: 'No',
        yes: 'Yes',
        cancel: 'Cancel'
      }
    },
    login: {
      greeting: 'Welcome!',
      login: 'Login',
      rememberMe: 'Remember me',
      password: 'Password',
      forgottenPassword: 'Forgotten password?',
      logout: 'Log out',
      wrong_password: 'Password is not correct!'
    }
}
